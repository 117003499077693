import * as React from 'react';
import { Modal, Button, Form, Checkbox, Input, Select, Message } from 'semantic-ui-react';
import { FeathersDropdown } from '@inkcloud/components';
import moment from 'moment';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Feathers } from '../../../bootstrap/feathers/feathers';
import { SemanticField } from '../../../common/Form';

interface IMassUpdaterModalProps {
  isOpen: boolean;
  onReload: () => void;
  onClose: () => void;
  onResponseMessage: (msg: string) => void;
  filter: any;
  total: number;
}

const submissionMapper = (values: any) => ({
  weight: values.isWeight ? values.weight : undefined,
  weightBasis: values.isWeight ? values.weightBasis : undefined,
  inventoryLowThreshold: values.isLowInventory ? values.inventoryLowThreshold : undefined,
  inventoryLowEmailNotify: values.isLowInventory
    ? {
        name: values?.name ?? undefined,
        email: values.email ?? undefined,
      }
    : undefined,
  productionChannel: values.isProductionChannel ? values.productionChannel : undefined,
  inventory: values.isProductType ? values.inventory === 'stock' : undefined,
  preferredSupplier: values.isPreferredSupplier ? values.preferredSupplier : undefined,
  workflowTemplate: values.isWorkflowTemplate ? values.workflowTemplate : undefined,
  associatedProducts: values.isAssociatedProducts
    ? values.associatedProducts?.map((p) => ({ product: p }))
    : undefined,

  designTemplates: values.isConnectedDesignTemplates ? values.designTemplates : undefined,
  initialStatus: values?.isInitialStatus ? values?.initialStatus : undefined,
  isCustomTrimEnabled: values?.isIsCustomTrimEnabled ? !!values?.isCustomTrimEnabled : undefined,
  isDesignEnabled: values?.isIsDesignEnabled ? !!values?.isDesignEnabled : undefined,
  designPrice: values?.isDesignEnabled ? values?.designPrice : undefined,
  timeMax: values?.isTurnaround ? values?.timeMax : undefined,
  timeMin: values?.isTurnaround ? values?.timeMin : undefined,
  isMSPublish: values?.isMSPublish,
  isMSUnPublish: values?.isMSUnPublish,
  microsite: values?.microsite || undefined,
  createMSPage: values?.isMSPublish || values?.isMSUnPublish ? values?.createMSPage : undefined,
  isHidden: values?.isHidden !== undefined && values?.isHidden,
});

const { useState } = React;

export const MassUpdaterModal: React.FunctionComponent<IMassUpdaterModalProps> = (props) => {
  const { isOpen, onReload, onClose, onResponseMessage, filter, total } = props;

  const MassUpdaterSchema = Yup.object().shape({
    weight: Yup.number().typeError('Must be a number'),
    weightBasis: Yup.number().typeError('Must be a number'),
    microsite: Yup.string().when(['isMSPublish', 'isMSUnPublish'], {
      is: (isMSPublish, isMSUnPublish) => isMSPublish || isMSUnPublish,
      then: Yup.string().required('Microsite is required'),
    }),
  });

  // const [values, setValues] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // const handleChange = (data: any) => setValues({
  //   ...values,
  //   [data.name]: data?.type === 'checkbox' ? data.checked : data.value
  // })

  const newFilter = filter?.rows?.reduce((acc, cur: any) => {
    const isAttribute = cur?.selectedFact?.startsWith('attributes');
    const isAddOns = cur?.selectedFact === 'addOns';
    let { selectedOperator } = cur;

    switch (selectedOperator) {
      case 'is':
        selectedOperator = '$in';
        break;
      case 'isNot':
        selectedOperator = '$nin';
        break;
      case 'greaterThan':
        selectedOperator = '$gt';
        break;
      case 'lessThan':
        selectedOperator = '$lt';
        break;
      default:
        selectedOperator = '$LIKE';
    }

    if (cur.selectedOperator === 'between') {
      if (!cur.value?.startValue && !cur.value?.endValue) {
        return acc;
      }

      return {
        ...acc,
        [cur.selectedFact]: {
          $gte: cur.value?.startValue
            ? moment(cur.value?.startValue).format('YYYY-MM-DD')
            : undefined,
          $lte: cur.value?.endValue ? moment(cur.value?.endValue).format('YYYY-MM-DD') : undefined,
        },
      };
    }

    const normalizeValue = Array.isArray(cur.value)
      ? cur.value.map((v) => (v.value ? v.value : v))
      : cur?.value?.value ?? cur.value;

    if (isAttribute) {
      const trimStr = cur?.selectedFact?.slice('attributes:'.length);

      return {
        ...acc,
        attributes: {
          ...(acc.attributes || {}),
          [trimStr]: normalizeValue,
        },
      };
    }

    if (isAddOns) {
      return {
        ...acc,
        addOns: {
          $elemMatch: {
            id: {
              [selectedOperator]: normalizeValue,
            },
            isEnabled: 1,
          },
        },
      };
    }

    return {
      ...acc,
      [cur.selectedFact === 'name' ? 'staticName' : cur.selectedFact]: {
        [selectedOperator]: normalizeValue,
      },
    };
  }, {});

  const handleSubmit = async (values: any) => {
    setErrorMessage('');
    setResponseMessage('');
    setIsSubmitting(true);
    try {
      const res: any = await Feathers.service('/products-legacy/mass-update-request').create({
        filter: newFilter,
        values,
      });

      if (res?.result) {
        onResponseMessage(res?.message);
        onReload();
        onClose();
      }
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal size="small" open={isOpen} onClose={onClose} closeIcon>
      <Modal.Header>Product Mass Updater</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{} as any}
          enableReinitialize={true}
          validationSchema={MassUpdaterSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              dirty,
              handleBlur,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = formProps;

            return (
              <FormikForm
                className={`ui form ${errorMessage ? 'error' : 'success'}`}
                autoComplete="off"
              >
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isInitialStatus"
                      label="Initial Production Status"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isInitialStatus && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="initialStatus"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <FeathersDropdown
                              search
                              selection
                              clearable
                              placeholder="Initial Production Status"
                              serviceName="/order-item-statuses"
                              query={{
                                $sort: { name: 1 },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: `${r.name}`,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                )}
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField component={Form.Checkbox} name="isWeight" label="Weight" />
                  </Form.Field>
                </Form.Group>
                {values?.isWeight && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        name="weight"
                        placeholder="Enter Weight"
                        value={values.weight}
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="weight"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        name="weightBasis"
                        placeholder="Quantity for weight"
                        value={values.weightBasis}
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="weightBasis"
                      />
                    </Form.Field>
                  </Form.Group>
                )}
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isLowInventory"
                      label="Low Inventory"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isLowInventory && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        name="inventoryLowThreshold"
                        placeholder="Low Threshold"
                      />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField component={Form.Input} name="name" placeholder="Notify Name" />
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        name="email"
                        placeholder="Notify Email"
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isProductionChannel"
                      label="Production Channel"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isProductionChannel && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="productionChannel"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <FeathersDropdown
                              search
                              selection
                              clearable
                              placeholder="Production Channel"
                              serviceName="/production/channels"
                              query={{
                                $sort: { name: 1 },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: `${r.name}`,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isProductType"
                      label="Product Type"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isProductType && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="inventory"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <Select
                              placeholder="Product Type"
                              options={[
                                { key: 'stock', value: 'stock', text: 'Stock' },
                                { key: 'on-demand', value: 'on-demand', text: 'On-demand' },
                              ]}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isPreferredSupplier"
                      label="Preferred Supplier"
                    />
                  </Form.Field>
                </Form.Group>

                {values?.isPreferredSupplier && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="preferredSupplier"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <FeathersDropdown
                              search
                              selection
                              clearable
                              placeholder="Preferred Supplier"
                              serviceName="production/vendors"
                              query={{
                                $sort: { companyName: 1 },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: `${r.companyName}`,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isWorkflowTemplate"
                      label="Workflow Template"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isWorkflowTemplate && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="workflowTemplate"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <FeathersDropdown
                              search
                              selection
                              clearable
                              placeholder="Workflow Template"
                              serviceName="job-scheduler/workflow-templates"
                              query={{
                                $sort: { name: 1 },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: `${r.name}`,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isAssociatedProducts"
                      label="Associated Products"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isAssociatedProducts && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="associatedProducts"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <FeathersDropdown
                              search
                              selection
                              multiple
                              clearable
                              placeholder="Associated Products"
                              serviceName="products-legacy"
                              query={{
                                $select: ['staticName', 'key'],
                                $sort: { staticName: 1 },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: `${r.staticName} - ${r.key}`,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isConnectedDesignTemplates"
                      label="Connected Design Templates"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isConnectedDesignTemplates && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="designTemplates"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <FeathersDropdown
                              search
                              selection
                              multiple
                              clearable
                              placeholder="Connected Design Templates"
                              serviceName="/design-templates"
                              query={{
                                $sort: { name: 1 },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: `${r.name}`,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isIsCustomTrimEnabled"
                      label="Custom Trim"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isIsCustomTrimEnabled && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        label="Enabled"
                        name={'isCustomTrimEnabled'}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      name="isIsDesignEnabled"
                      label="Graphic Design"
                    />
                  </Form.Field>
                </Form.Group>
                {values?.isIsDesignEnabled && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        label="Enabled"
                        name={'isDesignEnabled'}
                      />
                    </Form.Field>
                    {values.isDesignEnabled && (
                      <Form.Field>
                        <SemanticField
                          component={Form.Input}
                          name="designPrice"
                          placeholder="Design Price"
                        />
                      </Form.Field>
                    )}
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField component={Form.Checkbox} name="isTurnaround" label="Weight" />
                  </Form.Field>
                </Form.Group>
                {values?.isTurnaround && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        component={Form.Input}
                        name="timeMax"
                        placeholder="Turnaround Time Max"
                        value={values.timeMax}
                      />
                      <SemanticField
                        component={Form.Input}
                        name="timeMin"
                        placeholder="Turnaround Time Min"
                        value={values.timeMin}
                      />
                      <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="timeMax"
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      label="MS Publish"
                      name={'isMSPublish'}
                      checked={values.isMSPublish}
                      onChange={(e, data) => {
                        setFieldValue('isMSPublish', true);
                        setFieldValue('isMSUnPublish', false);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      label="MS UnPublish"
                      name={'isMSUnPublish'}
                      checked={values.isMSUnPublish}
                      onChange={(e, data) => {
                        setFieldValue('isMSPublish', false);
                        setFieldValue('isMSUnPublish', true);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                {(values?.isMSPublish || values?.isMSUnPublish) && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Field
                        name="microsite"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            <FeathersDropdown
                              search
                              selection
                              clearable
                              placeholder="Microsite"
                              serviceName="microsites"
                              query={{
                                $sort: { name: 1 },
                                $np: 1,
                              }}
                              resultMapper={(r) => ({
                                key: r._id,
                                value: r._id,
                                text: `${r.name}`,
                              })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: field.name },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />

                      {errors?.microsite && (
                        <Message negative size="tiny" content={errors.microsite as string} />
                      )}

                      {/* <ErrorMessage
                        render={(msg) => <Message negative size="tiny" content={msg} />}
                        name="microsite"
                      /> */}
                    </Form.Field>
                    <Form.Field>
                      <SemanticField
                        component={Form.Checkbox}
                        label="Create Microsite Page"
                        name={'createMSPage'}
                        checked={values.createMSPage}
                      />
                    </Form.Field>
                  </Form.Group>
                )}
                <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField
                      component={Form.Checkbox}
                      // toggle
                      // label={values?.isHidden ? 'True' : 'False'}
                      label="Is Hidden"
                      name={'isHidden'}
                      checked={values.isHidden}
                    />
                  </Form.Field>
                </Form.Group>
                {/* <Form.Group widths="equal">
                  <Form.Field>
                    <SemanticField component={Form.Checkbox} name="isIsHidden" label="Is Hidden" />
                  </Form.Field>
                </Form.Group>
                {values?.isIsHidden && (
                )} */}

                {responseMessage && <Message success content={responseMessage} />}
                {errorMessage && <Message error content={errorMessage} />}
                <Form.Group widths="equal">
                  <Form.Field>
                    These updates will apply to your currently applied filter <em>{total}</em>{' '}
                    product{total > 1 ? 's' : ''} will be affected
                  </Form.Field>
                  <Form.Field>
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        primary
                        content="Submit"
                        disabled={Object.keys(errors).length > 0 || isSubmitting}
                        loading={isSubmitting}
                        onClick={() => handleSubmit(submissionMapper(values))}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </FormikForm>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
