import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import feathers from './../../bootstrap/feathers';
import { PageHeaderRow, EmptyWrapper, PreLoader, To } from '@inkcloud/components';
import {
  Segment,
  Table,
  Header,
  Grid,
  Label,
  Divider,
  Form,
  Checkbox,
  Button,
  Message,
} from 'semantic-ui-react';
import { DetailTable } from '../../common/DetailTable';
import NumberFormat from 'react-number-format';

interface VoidInvoiceProps {}

const { useState, useEffect } = React;

export const VoidInvoice: React.FunctionComponent<
  VoidInvoiceProps & RouteComponentProps<{ id?: string }>
> = (props) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  const [queryResult, setQueryResult] = useState(null);
  const [payments, setPayments] = useState([]);
  const [orderHumanId, setOrderHumanId] = useState('');
  const [errorMessage, setErrorMessage] = useState({ void: '', order: '' });
  const [responseMessage, setResponseMessage] = useState('');
  const [isVoid, setIsVoid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [orderItemIds, setOrderItemIds] = useState([]);

  const [convertToCreditMemo, setConvertToCreditMemo] = useState(false);

  useEffect(() => {
    const loadPayment = async (res: any) => {
      const resPayments: any = await feathers.service('payments/applied').find({
        query: {
          invoice: res.invoice,
          $populate: ['payment'],
          $sort: { name: 1 },
        },
      });
      setPayments(resPayments.data);
    };

    const loadOrder = async () => {
      const [error, res] = (await To(feathers.service('orders').get(id, { query: {} }))) as any;

      if (res) {
        setOrderHumanId(res.humanId);
        setIsVoid(res.isVoid ? res.isVoid : false);
        loadPayment(res);
      } else {
        setErrorMessage({ ...errorMessage, order: error.message });
      }
    };

    const loadOrderItem = async () => {
      const res = await feathers.service('order-items').find({
        query: {
          order: id,
          $populate: ['attributes.value', 'category'],
          $limit: 200,
        },
      });
      setQueryResult(res);
    };

    loadOrderItem();
    loadOrder();
  }, [id, isLoading]);

  const handleVoid = async () => {
    setIsLoading(true);
    setErrorMessage({ ...errorMessage, void: '' });
    setResponseMessage('');
    let value = {
      orderId: id,
      convertToCreditMemo,
      orderItemIds,
    };

    // const filteredItem =
    //   queryResult &&
    //   queryResult.data.filter(q => q.productionStatus.jobStatus !== "VOID");

    // if (
    //   orderItemIds.length < 1 ||
    //   orderItemIds.length === queryResult.data.length ||
    //   orderItemIds.length === filteredItem.length
    // ) {
    //   delete value.orderItemIds;
    // }

    if (orderItemIds.length === 0) {
      delete value.orderItemIds;
    }

    try {
      const res = await feathers.service('orders/void').create(value, { query: {} });

      setIsLoading(false);
      if (res.result !== undefined && !res.result) {
        setErrorMessage({ ...errorMessage, void: res.message });
      } else {
        setResponseMessage(res.message);
        setOrderItemIds([]);
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage({
        ...errorMessage,
        void:
          e.code && e.code < 500
            ? e.message
            : 'We are experiencing technical difficulties. Please try again',
      });
    }
  };

  const handleSelectAll = (v: any, data: any) => {
    if (data.checked) {
      const ids =
        v &&
        v.reduce((acc, qr) => {
          {
            qr.productionStatus.jobStatus !== 'VOID' && acc.push(qr._id);
          }
          return acc;
        }, []);
      setOrderItemIds(ids);
    } else {
      setOrderItemIds([]);
    }
  };

  const handleCheck = (data: any, id: string) => {
    if (data.checked) {
      setOrderItemIds([...orderItemIds, id]);
    } else {
      const filtered = orderItemIds.filter((oi) => id !== oi);
      setOrderItemIds(filtered);
    }
  };

  if (!queryResult || !payments) {
    return <PreLoader />;
  }

  const sortedOrderItems = queryResult?.data
    ?.map((i: any) => ({
      ...i,
      priority: parseInt(i.humanId.split('-')[1]),
    }))
    ?.sort((a, b) => a.priority - b.priority);

  return (
    <>
      <PageHeaderRow header="Void Invoice" subheader="Accounting" />
      <Divider hidden />
      {responseMessage && <Message info content={responseMessage} />}
      {(errorMessage.order || errorMessage.void) && (
        <Message warning content={errorMessage.order || errorMessage.void} />
      )}
      <Message content="To void the whole order, click 'Void Order'. To void only parts of the order, use the checkboxes to indicate which items to void" />

      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Segment.Group>
              <Segment>
                <Header as="h4">Void Order</Header>
              </Segment>
              <Segment className={`${isLoading && 'loading'}`}>
                <strong>Order: </strong> {orderHumanId && orderHumanId}
                {payments?.length > 0 && (
                  <Segment secondary>
                    <Header as="h4" content="Applied Payments" />
                    <Table basic="very" compact>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                          <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {payments &&
                          payments.map((p: any, index: number) => (
                            <Table.Row key={index}>
                              <Table.Cell>
                                <FormattedDate value={p.payment.createdAt} />
                              </Table.Cell>
                              <Table.Cell>{p.payment.description}</Table.Cell>
                              <Table.Cell textAlign="right">
                                <NumberFormat
                                  value={p.amount || 0.0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  prefix={p.payment.type === 'REFUND' ? '($' : '$'}
                                  suffix={p.payment.type === 'REFUND' ? ')' : null}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </Segment>
                )}
                {!isVoid && (
                  <Form>
                    <Form.Field>
                      {payments.length > 0 && (
                        <Checkbox
                          toggle
                          label="Convert to Credit Memo"
                          onChange={(e, data) => setConvertToCreditMemo(data.checked)}
                        />
                      )}
                    </Form.Field>
                    <Form.Field>
                      {!errorMessage.order && (
                        <Button primary onClick={handleVoid} content="Void Order" />
                      )}
                    </Form.Field>
                  </Form>
                )}
              </Segment>
            </Segment.Group>
          </Grid.Column>
          <Grid.Column width={10}>
            <EmptyWrapper queryResult={queryResult} isStateLoading={false}>
              <Segment className={`${isLoading && 'loading'}`}>
                <Table size={'small'} sortable unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        {!isVoid &&
                          sortedOrderItems.filter(
                            (item) => item.productionStatus.jobStatus !== 'VOID'
                          ).length > 0 && (
                            <Checkbox
                              onChange={(e, data) => handleSelectAll(sortedOrderItems, data)}
                            />
                          )}
                      </Table.HeaderCell>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Details</Table.HeaderCell>
                      <Table.HeaderCell>Job Name</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {sortedOrderItems?.map((item: any, index: number) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {!isVoid && item.productionStatus.jobStatus !== 'VOID' && (
                            <Checkbox
                              checked={
                                orderItemIds.length > 0 &&
                                orderItemIds.find((oi) => oi === item._id)
                                  ? true
                                  : false
                              }
                              onChange={(e, data) => handleCheck(data, item._id)}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>{item.humanId}</Table.Cell>
                        <Table.Cell>{item.category && item.category.name}</Table.Cell>
                        <Table.Cell collapsing>
                          <DetailTable
                            quantity={item.quantity}
                            attributes={item.attributes}
                            addOns={item.addOns}
                            description={item.description}
                          />
                        </Table.Cell>
                        <Table.Cell>{item.customerJobName}</Table.Cell>
                        <Table.Cell>
                          <Label color="teal">{item.productionStatus.description}</Label>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
            </EmptyWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
