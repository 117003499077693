import * as React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  StackDivider,
  Box,
  Text,
  Radio,
  RadioGroup,
  Badge,
  ButtonGroup,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import { FaEyeDropper } from 'react-icons/fa';
import { ModelTypes } from '@inkcloud/icapi-types';
import { FormattedDate } from 'react-intl';

export interface IWizardProps {
  billingReconciliation: ModelTypes.VendorBillingReconciliations;
  handleSubmitBilling: () => void;
  handleReconciliationApproval: (r: boolean, rejectionReason?: string) => void;
  handleViewSupplierInvoice: () => void;
  isLoading: boolean;
}

const Wizard: React.FunctionComponent<IWizardProps> = (props) => {
  const {
    billingReconciliation,
    handleSubmitBilling,
    handleReconciliationApproval,
    handleViewSupplierInvoice,
    isLoading = false,
  } = props;

  const [reconciliationApproval, setReconciliationApproval] = React.useState<string>('0');
  const [rejectionReason, setRejectionReason] = React.useState<string>('');

  if (!billingReconciliation) {
    throw new Error('Invalid Billing Reconiliation');
  }
  const {
    respondedAt,
    supplierResponse,
    isSupplierInvoiceApproved,
    attachments,
    supplierInvoiceApprovedAt,
  } = billingReconciliation;

  const hasSupplierResponded = billingReconciliation.status !== 'awaiting-supplier-response';

  const canTenantApproveReconciliation = billingReconciliation.status === 'pending-review';
  const invoiceDoc = attachments?.find((a) => a?.label === 'Invoice');
  const hasInvoiceDoc = !!invoiceDoc;

  const canSubmitBilling =
    supplierResponse === 'approved' && billingReconciliation.billingStatus !== 'sent';
  const alreadySubmittedForBilling = billingReconciliation.billingStatus === 'sent';

  let resolvedSupplierResponse: any = supplierResponse;
  if (!resolvedSupplierResponse || resolvedSupplierResponse === '') {
    resolvedSupplierResponse =
      billingReconciliation.status === 'awaiting-supplier-response' ? 'pending' : 'approved';
  }

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Billing Reconiciliation</Heading>
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Supplier Reconciliation
            </Heading>
            <Text pt="2" fontSize="sm">
              Review the supplier's response. You can do this once a supplier has submitted a
              response.
            </Text>
            {hasSupplierResponded && (
              <>
                <Box mt={8}>
                  Supplier responded with{' '}
                  <Badge colorScheme={resolvedSupplierResponse === 'approved' ? 'green' : 'red'}>
                    {resolvedSupplierResponse}
                  </Badge>{' '}
                  on{' '}
                  <Badge>
                    <FormattedDate value={respondedAt} />
                  </Badge>
                </Box>
                {canTenantApproveReconciliation && (
                  <Box mt={8}>
                    How would you like to respond to the Supplier's submission?
                    <RadioGroup
                      defaultValue="1"
                      mb={4}
                      value={reconciliationApproval}
                      onChange={setReconciliationApproval}
                    >
                      <Stack spacing={4} direction="row">
                        <Radio value="1">Approve</Radio>
                        <Radio value="0">Reject</Radio>
                      </Stack>
                    </RadioGroup>
                    {reconciliationApproval === '0' && (
                      <>
                        <FormLabel>Rejection Reason</FormLabel>
                        <Textarea
                          mb={4}
                          value={rejectionReason}
                          onChange={(e) => setRejectionReason(e.target.value)}
                        ></Textarea>
                      </>
                    )}
                    <Button
                      colorScheme="gray"
                      size={'sm'}
                      onClick={() =>
                        handleReconciliationApproval(
                          reconciliationApproval === '1',
                          rejectionReason
                        )
                      }
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Supplier Invoice
            </Heading>
            <Text pt="2" fontSize="sm">
              Review and approve supplier's invoice. You can do this once you have approved the
              reconciliation and the supplier uploads the invoice document
            </Text>
            {hasInvoiceDoc && (
              <>
                <Box mt={8}>
                  {hasInvoiceDoc && (
                    <Button
                      leftIcon={<FaEyeDropper />}
                      colorScheme="green"
                      variant={'outline'}
                      size={'sm'}
                      onClick={handleViewSupplierInvoice}
                    >
                      View Supplier Invoice
                    </Button>
                  )}

                  {!isSupplierInvoiceApproved && (
                    <>
                      <Text mt={4}>Supplier invoice has not yet been approved</Text>
                    </>
                  )}

                  {isSupplierInvoiceApproved && (
                    <Box mt={8}>
                      Invoice was approved on{' '}
                      <Badge>
                        <FormattedDate value={supplierInvoiceApprovedAt} />
                      </Badge>
                    </Box>
                  )}
                </Box>
              </>
            )}
            {!hasInvoiceDoc && (
              <Box mt={8}>Supplier has not uploaded the invoice document yet.</Box>
            )}
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Billing
            </Heading>
            <Text pt="2" fontSize="sm">
              Submit for billing. You can do this once the supplier has approved the reconciliation.
            </Text>
            <Box mt={8}>
              <Button
                colorScheme="gray"
                size={'sm'}
                onClick={handleSubmitBilling}
                disabled={!canSubmitBilling}
              >
                Submit for Billing
              </Button>
            </Box>
            {alreadySubmittedForBilling && (
              <Box mt={8}>
                <Badge colorScheme="green">Billing Sent</Badge> at{' '}
                <FormattedDate value={billingReconciliation.billingSentAt} />
              </Box>
            )}
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default Wizard;
