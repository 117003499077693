import * as React from 'react';
import * as Yup from 'yup';
import { Segment, Header, Checkbox, Table, Dropdown, Form, Button, Modal } from 'semantic-ui-react';
import { Formik, Form as FormikForm, FieldArray, Field, ErrorMessage, FieldProps } from 'formik';
import { FeathersDropdown } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import {
  semanticFormDropdownField,
  semanticFormField,
  semanticFormToggleField,
  semanticFormCheckboxGroupField,
  semanticFormRadioGroupField,
  semanticFormCheckboxField,
  semanticFormRadioField,
  validateRequired,
  normalizeBoolean,
  formatBoolean,
  groupBy,
  slugify2 as slugifyKey,
  DownloadZip,
} from '../../../common';

interface AddOnPricingFormProps {
  initialValues: any;
  isEdit: boolean;
  onSubmit: (v: any) => void;
}

const Checkbox2 = (props) => (
  <Form.Field>
    <Checkbox
      label={props.label}
      checked={props.input.checked}
      onChange={(e, data) => props.input.onChange(data.checked)}
    />
  </Form.Field>
);

const customFieldOptions = [
  { text: 'Text', value: 'text' },
  { text: 'Select', value: 'select' },
  { text: 'Multi Select', value: 'multi-select' },
  { text: 'Datepicker', value: 'datepicker' },
  { text: 'Checkbox', value: 'checkbox' },
];

const AddOnPricingFormSchema = Yup.object().shape({
  addOnId: Yup.string().required('Add on ID must be selected'),
  turnaround: Yup.number().typeError('Turnaround Time must be a number'),
  setup: Yup.number().typeError('Setup must be a number'),
  minimum: Yup.number().typeError('Minimum must be a number'),
  rate: Yup.number().typeError('Rate must be a number'),
  sqFtRate: Yup.number().typeError('Rate/SqFt must be a number'),
  customFields: Yup.array().default([]),
});

const { useState, useEffect } = React;

export const AddOnPricingForm: React.FunctionComponent<AddOnPricingFormProps> = (props) => {
  const { initialValues, isEdit, onSubmit } = props;

  const [addOns, setAddOns] = useState([]);
  const [baseAddOns, setBaseAddOns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);

  const handleCustomFieldModal = (index: number | null) => {
    setIsOpen(!isOpen);
    setModalIndex(index);
  };

  useEffect(() => {
    const loadAllAddOns = async () => {
      const res: any = await feathers.service('products-legacy/add-ons').find({
        query: {
          $sort: { name: 1 },
          $np: 1,
        },
      });
      setAddOns(res);
    };

    const loadAddOnsBase = async () => {
      const res: any = await feathers.service('products-legacy/add-ons-base').find({
        query: {
          $sort: { name: 1 },
        },
      });
      setBaseAddOns(res);
    };

    if (!isEdit) {
      loadAllAddOns();
      loadAddOnsBase();
    }
  }, []);

  const filteredAddOns =
    baseAddOns.length > 0 && baseAddOns.filter((b) => !addOns.find((a) => a.addOnId === b._id));

  let newInitialValues;

  if (!isEdit) {
    newInitialValues = {
      ...initialValues,
      addOnId: '',
      customFields: [],
    };
  }

  // Not needed with our revised approach

  // Removed renderCustomOptions - no longer needed as it's handled in renderCustomFields

  const renderCustomFields = ({ form, name }) => {
    const { values, setFieldValue } = form;
    const fields = values[name] || [];

    const addField = () => {
      const updatedFields = [...fields, { type: 'text' }];
      setFieldValue(name, updatedFields);
    };

    const removeField = (index) => {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1);
      setFieldValue(name, updatedFields);
    };

    const showOptionsModal = (index) => {
      handleCustomFieldModal(index);
    };

    return (
      <div>
        {fields.map((field, index) => (
          <Form.Group widths="equal" key={index}>
            <Form.Field>
              <label>Label</label>
              <Field name={`${name}[${index}].label`} />
            </Form.Field>
            <Form.Field>
              <label>Key</label>
              <Field name={`${name}[${index}].key`} />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <Field name={`${name}[${index}].type`}>
                {({ field, form }) => (
                  <Dropdown
                    selection
                    fluid
                    search
                    options={customFieldOptions}
                    value={field.value || 'text'}
                    onChange={(e, data) => form.setFieldValue(field.name, data.value)}
                  />
                )}
              </Field>
              {(field.type === 'select' || field.type === 'multi-select') && (
                <>
                  <div style={{ marginTop: '.5em' }}>
                    <Button
                      type="button"
                      size="tiny"
                      content={
                        field.options && field.options.length > 0 ? 'Edit Options' : 'Add Options'
                      }
                      onClick={() => showOptionsModal(index)}
                    />
                  </div>
                  {isOpen && modalIndex === index && (
                    <Modal
                      open={isOpen && modalIndex === index}
                      onClose={() => handleCustomFieldModal(null)}
                      closeIcon
                    >
                      <Modal.Header>Add Options</Modal.Header>
                      <Modal.Content>
                        <FieldArray
                          name={`${name}[${index}].options`}
                          render={(optionProps) => {
                            const optionFields = form.values[name][index].options || [];
                            return (
                              <div className="ui form">
                                {optionFields.map((option, optIndex) => (
                                  <Form.Group widths="equal" key={optIndex}>
                                    <Form.Field>
                                      <label>Key</label>
                                      <Field name={`${name}[${index}].options[${optIndex}].key`} />
                                    </Form.Field>
                                    <Form.Field>
                                      <label>Value</label>
                                      <Field
                                        name={`${name}[${index}].options[${optIndex}].value`}
                                      />
                                    </Form.Field>
                                    <div className="field">
                                      <label>&nbsp;</label>
                                      <Button
                                        type="button"
                                        icon="remove"
                                        onClick={() => {
                                          const updatedOptions = [...optionFields];
                                          updatedOptions.splice(optIndex, 1);
                                          form.setFieldValue(
                                            `${name}[${index}].options`,
                                            updatedOptions
                                          );
                                        }}
                                      />
                                    </div>
                                  </Form.Group>
                                ))}
                                <Button
                                  size="tiny"
                                  content="Add Option"
                                  type="button"
                                  onClick={() => {
                                    const currentOptions = optionFields || [];
                                    form.setFieldValue(`${name}[${index}].options`, [
                                      ...currentOptions,
                                      { key: '', value: '' },
                                    ]);
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          type="button"
                          size="tiny"
                          primary
                          content="Done"
                          onClick={() => handleCustomFieldModal(null)}
                        />
                      </Modal.Actions>
                    </Modal>
                  )}
                </>
              )}
            </Form.Field>
            <Form.Field>
              <label>&nbsp;</label>
              <Checkbox
                label="Required"
                checked={field.isRequired || false}
                onChange={(e, data) => {
                  setFieldValue(`${name}[${index}].isRequired`, data.checked);
                }}
              />
            </Form.Field>
            <div>
              <div className="field">
                <label>&nbsp;</label>
                <Button type="button" icon="remove" onClick={() => removeField(index)} />
              </div>
            </div>
          </Form.Group>
        ))}

        <Button size="tiny" content="Add Field" type="button" onClick={addField} />
      </div>
    );
  };

  return (
    <Formik
      initialValues={!isEdit ? newInitialValues : initialValues}
      validationSchema={AddOnPricingFormSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(props) => {
        const { values, isSubmitting } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'} `}>
            <Form.Field>
              {!isEdit && (
                <>
                  <Field
                    name="addOnId"
                    render={({ field }: FieldProps<any>) => (
                      <Dropdown
                        search
                        selection
                        clearable
                        placeholder={'Select an add on...'}
                        options={
                          filteredAddOns.length > 0 &&
                          filteredAddOns?.map((f) => ({ key: f._id, value: f._id, text: f.name }))
                        }
                        onChange={(e, data) =>
                          field.onChange({ target: { value: data.value, name: 'addOnId' } } as any)
                        }
                      />
                    )}
                  />
                  <ErrorMessage name="addOnId" />
                </>
              )}
            </Form.Field>
            <Form.Field>
              <label>Turnaround Time</label>
              <Field name="turnaround" />
              <ErrorMessage name="turnaround" />
            </Form.Field>
            <Form.Field>
              <label>Setup</label>
              <Field name="setup" />
              <ErrorMessage name="setup" />
            </Form.Field>
            <Form.Field>
              <label>Minimum</label>
              <Field name="minimum" />
              <ErrorMessage name="minimum" />
            </Form.Field>
            <Form.Field>
              <label>Rate</label>
              <Field name="rate" />
              <ErrorMessage name="rate" />
            </Form.Field>
            <Form.Field>
              <label>Rate/SqFt</label>
              <Field name="sqFtRate" />
              <ErrorMessage name="sqFtRate" />
            </Form.Field>

            <Header attached="top" as="h5">
              Custom Fields
            </Header>
            <Segment attached="bottom">
              <FieldArray name="customFields" render={renderCustomFields} />
            </Segment>

            <Button type="submit" primary content="Save" disabled={isSubmitting} />
          </FormikForm>
        );
      }}
    </Formik>
  );
};
