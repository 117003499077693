import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import Index from './IndexComponent';
import Microsites from './microsites';
import SalesReport from './sales';
import SalesLightReport from './sales-report-light';
import SalesTaxReport from './sales-tax';
import SalesTeamReport from './sales-team';
import CustomerSalesReport from './customer-sales';
import CustomerGrowthReport from './customer-growth';
import AgingReport from './aging';
import PaymentReport from './payment';
import Results from './results';
import InactiveTenantCustomerReport from './tenant-inactive-customers';
import Customs from './customs';
import Rfq from './rfq';
import Purchasing from './purchasing';
// import Financial from './financial';
import FinancialReport from './financial/FinancialReport';
import ProductionReport from './production/ProductionReport';

const routes = (props) => (
  <Switch>
    <Route path="/reports/microsites" component={Microsites} />
    <Route path="/reports/sales" component={SalesReport} />
    <Route path="/reports/sales-light" component={SalesReport} />
    <Route path="/reports/sales-tax" component={SalesTaxReport} />
    <Route path="/reports/sales-team" component={SalesTeamReport} />
    <Route path="/reports/customer-sales" component={CustomerSalesReport} />
    <Route path="/reports/customer-growth" component={CustomerGrowthReport} />
    <Route path="/reports/aging" component={AgingReport} />
    <Route path="/reports/payment" component={PaymentReport} />
    <Route path="/reports/inactive-tenant-customer" component={InactiveTenantCustomerReport} />
    <Route path="/reports/results" component={Results} />
    {/* <Route path="/reports/financial" component={Financial} /> */}
    <Route path="/reports/customs" component={Customs} />
    <Route path="/reports/rfq" component={Rfq} />
    <Route path="/reports/purchasing" component={Purchasing} />
    <Route path="/reports/financial" component={FinancialReport} />
    <Route path="/reports/production" component={ProductionReport} />

    <Route path="/reports" component={Index} />
  </Switch>
);

export default routes;
