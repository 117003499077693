import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { PageHeaderRow } from '@inkcloud/components';
import Feathers from '../../../bootstrap/feathers';
import FilterForm from '../FilterForm2';

function loadData(values) {
  const params: any = { query: {} };

  return Feathers.service('/reports/v3/results').create(
    {
      query: {
        start: values.start,
        end: values.end,
        type: values?.status?.value ?? 'inactive',
      },
      reportKey: 'tenant-inactive-customers',
      reportName: 'Tenant Inactive Customers',
    },
    params
  );
}

export default function InactiveCustomerReport() {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState({ start: null, end: null, microsites: [] });

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setErrorMessage('');
    try {
      setIsSubmitting(true);
      const res = await loadData(values);

      history.push('/reports/results');
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e?.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <PageHeaderRow header="Inactive Customers" subheader="Reports">
        <Button onClick={() => history.goBack()}>Back</Button>
      </PageHeaderRow>

      <Box p={3} borderWidth={1} borderRadius="md" bg="white">
        <FilterForm
          values={values}
          isSubmitting={isSubmitting}
          hasMicrosites={false}
          hasStatus
          errorMessage={errorMessage}
          onChange={(v) => {
            setErrorMessage('');
            setValues(v);
          }}
          onSubmit={handleSubmit}
        />
      </Box>
    </div>
  );
}
