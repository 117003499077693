import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  StackDivider,
  VStack,
  HStack,
  Flex,
  Spacer,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';

interface ReportBoxProps {
  children?: React.ReactNode;
  url: string;
}
const ReportBox: React.FunctionComponent<ReportBoxProps> = (props) => {
  const { children, url } = props;

  return (
    <Box
      px={4}
      py={2}
      as={Link}
      to={url}
      _hover={{
        backgroundColor: 'gray.100',
      }}
    >
      {children}
    </Box>
  );
};

const ReportHeading = ({ value }) => (
  <Text size={'md'} mb={1}>
    {value}
  </Text>
);

const ReportDescription = ({ value }) => <Text size={'md'}>{value}</Text>;

interface IIndexCompoenntProps {}

const IndexCompoennt: React.FunctionComponent<IIndexCompoenntProps> = (props) => {
  const a = '';

  return (
    <>
      <Flex mb={16}>
        <Box>
          <Heading fontWeight={'normal'} size="xl">
            Reports
          </Heading>
        </Box>
        <Spacer />
        <Box>
          <Button
            leftIcon={<ViewIcon />}
            as={Link}
            to={'/reports/results'}
            colorScheme={'blue'}
            size={'md'}
          >
            View Report Results
          </Button>
        </Box>
      </Flex>
      <Box>
        <SimpleGrid columns={2} spacing={10}>
          <Box>
            <Box bgColor={'white'} p={4}>
              <Heading p={2} as={'h4'} size={'md'}>
                General
              </Heading>

              <Box borderWidth={1} borderColor={'gray.200'}>
                <VStack
                  divider={<StackDivider borderColor="gray.200" />}
                  spacing={1}
                  align="stretch"
                >
                  <ReportBox url={'/reports/customs'}>
                    <ReportHeading value={'Custom Reports'} />
                    <Text fontSize={'xs'}>Custom built reports</Text>
                  </ReportBox>

                  <ReportBox url={'/reports/sales'}>
                    <ReportHeading value={'Sales'} />
                    <Text fontSize={'xs'}>Order-item level details </Text>
                  </ReportBox>
                  <ReportBox url={'/reports/sales-light'}>
                    <ReportHeading value={'Sales Light'} />
                    <Text fontSize={'xs'}>Order-item level details </Text>
                  </ReportBox>
                  <ReportBox url={'/reports/sales-tax'}>
                    <ReportHeading value={'Sales Tax'} />
                    <Text fontSize={'xs'}>General sales tax overview</Text>
                  </ReportBox>

                  <ReportBox url={'/reports/sales-team'}>
                    <ReportHeading value={'Sales Team'} />
                    <Text fontSize={'xs'}>Sales grouped by sales people </Text>
                  </ReportBox>

                  <ReportBox url={'/reports/customer-sales'}>
                    <ReportHeading value={'Sales by Customer'} />
                    <Text fontSize={'xs'}>Sales by top customers </Text>
                  </ReportBox>

                  <ReportBox url={'/reports/customer-growth'}>
                    <ReportHeading value={'Customer Growth'} />
                    <Text fontSize={'xs'}>Sales over time by customer </Text>
                  </ReportBox>

                  <ReportBox url={'/reports/payment'}>
                    <ReportHeading value={'Payment'} />
                    <Text fontSize={'xs'}>Overview of payments</Text>
                  </ReportBox>
                  <ReportBox url={'/reports/inactive-tenant-customer'}>
                    <ReportHeading value={'Inactive Customers (Tenant Level)'} />
                    <Text fontSize={'xs'}>
                      Customers that have not placed orders for a duration
                    </Text>
                  </ReportBox>
                </VStack>
              </Box>

              <br />
              <br />
              <Heading p={2} as={'h4'} size={'md'}>
                Purchasing
              </Heading>

              <Box borderWidth={1} borderColor={'gray.200'}>
                <VStack
                  divider={<StackDivider borderColor="gray.200" />}
                  spacing={1}
                  align="stretch"
                >
                  <ReportBox url={'/reports/rfq'}>
                    <ReportHeading value={'RFQ KPIs'} />
                    <Text fontSize={'xs'}>Various RFQ KPIs </Text>
                  </ReportBox>

                  <ReportBox url={'/reports/purchasing?reportKey=supplier-spend'}>
                    <ReportHeading value={'Supplier Spend'} />
                    <Text fontSize={'xs'}>Spend per supplier</Text>
                  </ReportBox>
                  <ReportBox url={'/reports/purchasing?reportKey=rfq-supplier-win-loss'}>
                    <ReportHeading value={'RFQ Supplier Win/Loss'} />
                    <Text fontSize={'xs'}>Win/loss per supplier</Text>
                  </ReportBox>
                  <ReportBox url={'/reports/purchasing?reportKey=rfq-win-loss'}>
                    <ReportHeading value={'RFQ Win/Loss'} />
                    <Text fontSize={'xs'}>Overall win/loss</Text>
                  </ReportBox>

                  <ReportBox url={'/reports/purchasing?reportKey=rfq-by-client'}>
                    <ReportHeading value={'RFQs By Client'} />
                    <Text fontSize={'xs'}>Count and ratios by client</Text>
                  </ReportBox>

                  <ReportBox url={'/reports/purchasing?reportKey=rfq-by-requestor'}>
                    <ReportHeading value={'RFQs By Requestor'} />
                    <Text fontSize={'xs'}>Counts and ratios by requestor</Text>
                  </ReportBox>

                  <ReportBox url={'/reports/purchasing?reportKey=rfq-by-pc'}>
                    <ReportHeading value={'RFQs By PC'} />
                    <Text fontSize={'xs'}>Count and ratios by PC</Text>
                  </ReportBox>

                  {/* <ReportBox url={'/reports/sales-team'}>
                    <ReportHeading value={'Sales Team'} />
                    <Text fontSize={'xs'}>Sales grouped by sales people </Text>
                  </ReportBox>

                  <ReportBox url={'/reports/customer-sales'}>
                    <ReportHeading value={'Sales by Customer'} />
                    <Text fontSize={'xs'}>Sales by top customers </Text>
                  </ReportBox>

                  <ReportBox url={'/reports/customer-growth'}>
                    <ReportHeading value={'Customer Growth'} />
                    <Text fontSize={'xs'}>Sales over time by customer </Text>
                  </ReportBox>

                  <ReportBox url={'/reports/payment'}>
                    <ReportHeading value={'Payment'} />
                    <Text fontSize={'xs'}>Overview of payments</Text>
                  </ReportBox>
              */}
                </VStack>
              </Box>
            </Box>
          </Box>
          <Box bgColor={'white'} p={4}>
            <Heading p={2} as={'h4'} size={'md'}>
              Microsite Reports
            </Heading>
            <Box borderWidth={1} borderColor={'gray.200'}>
              <VStack divider={<StackDivider borderColor="gray.200" />} spacing={2} align="stretch">
                <ReportBox url={'/reports/microsites/orders-flattened'}>
                  <ReportHeading value={'Orders'} />
                  <Text fontSize={'sm'}>Order-item level details </Text>
                </ReportBox>
                <ReportBox url={'/reports/microsites/shipped-orders-flattened'}>
                  <ReportHeading value={'Shipped'} />
                  <Text fontSize={'xs'}>Order-items that have shipped</Text>
                </ReportBox>
                <ReportBox url={'/reports/microsites/inactive-customers'}>
                  <ReportHeading value={'Inactive Customers'} />
                  <Text fontSize={'xs'}>Customers that have not placed orders for a duration</Text>
                </ReportBox>

                <ReportBox url={'/reports/microsites/inventory'}>
                  <ReportHeading value={'Inventory'} />
                  <Text fontSize={'xs'}>Overall inventory </Text>
                </ReportBox>
                <ReportBox url={'/reports/microsites/inventory-v2'}>
                  <ReportHeading value={'Inventory V2'} />
                  <Text fontSize={'xs'}>Inventory with broken out costs FIFO </Text>
                </ReportBox>
                <ReportBox url={'/reports/microsites/inventory-movements'}>
                  <ReportHeading value={'Inventory Movements'} />
                  <Text fontSize={'xs'}>View specific ledger movements</Text>
                </ReportBox>
                <ReportBox url={'/reports/microsites/inventory-locations'}>
                  <ReportHeading value={'Inventory Locations'} />
                  <Text fontSize={'xs'}>Warehouse inventory locations</Text>
                </ReportBox>

                <ReportBox url={'/reports/microsites/inventory-product-ledger'}>
                  <ReportHeading value={'Inventory Ledger'} />
                  <Text fontSize={'xs'}>Ledger report with running balance</Text>
                </ReportBox>

                <ReportBox url={'/reports/microsites/fulfillment'}>
                  <ReportHeading value={'Fulfillment'} />
                  <Text fontSize={'xs'}>Fulfillment details of products</Text>
                </ReportBox>

                <ReportBox url={'/reports/microsites/fulfillment-by-order'}>
                  <ReportHeading value={'Fulfillment by Order'} />
                  <Text fontSize={'xs'}>Fulfillment by order</Text>
                </ReportBox>

                <ReportBox url={'/reports/microsites/inventory-backorders'}>
                  <ReportHeading value={'Inventory Backorder'} />
                  <Text fontSize={'xs'}>View backorder report</Text>
                </ReportBox>

                <ReportBox url={'/reports/microsites/user-allotments'}>
                  <ReportHeading value={'User Allotments'} />
                  <Text fontSize={'xs'}>View user allotment usage and availability </Text>
                </ReportBox>
                <ReportBox url={'/reports/microsites/user-usage'}>
                  <ReportHeading value={'User Usage'} />
                  <Text fontSize={'xs'}>View user usage per SKU </Text>
                </ReportBox>
              </VStack>
            </Box>
            <br />
            <br />

            <Heading p={2} as={'h4'} size={'md'}>
              Production
            </Heading>

            <Box borderWidth={1} borderColor={'gray.200'}>
              <VStack divider={<StackDivider borderColor="gray.200" />} spacing={1} align="stretch">
                <ReportBox url={'/reports/production'}>
                  <ReportHeading value={'Production'} />
                  <Text fontSize={'xs'}>Production Status Time</Text>
                </ReportBox>
              </VStack>
            </Box>
          </Box>
        </SimpleGrid>

        {/* <Flex>
        <Box h='40px' bg='yellow.200' width={'45%'} border={1} borderColor={'gray.200'} >
        <VStack
            divider={<StackDivider borderColor='gray.200' />}
            spacing={4}
            align='stretch'
          >
            <Box h='40px' bg='yellow.200'>
              1
            </Box>
            <Box h='40px' bg='tomato'>
              2
            </Box>
            <Box h='40px' bg='pink.100'>
              3
            </Box>
          </VStack>
        </Box>
        <Spacer />
        <Box h='40px' bg='tomato' width={'45%'}  border={1} borderColor={'gray.200'}>
        <VStack
            divider={<StackDivider borderColor='gray.200' />}
            spacing={4}
            align='stretch'
          >
            <Box h='40px' bg='yellow.200'>
              1
            </Box>
            <Box h='40px' bg='tomato'>
              2
            </Box>
            <Box h='40px' bg='pink.100'>
              3
            </Box>
          </VStack>

        </Box>
      </Flex>
 */}
      </Box>
    </>
  );
};

export default IndexCompoennt;
