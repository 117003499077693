// import * as React from 'react';
// import { Button, Segment, Message } from 'semantic-ui-react';
// import { DetailWrapper, DetailFeaturesProps } from './../../../bootstrap/DetailWrapper';
// import { ContactForm } from './CustomerForm';
// import { PageHeaderRow, PreLoader } from '@inkcloud/components';
// import { Link, RouteComponentProps } from 'react-router-dom';
// // import { ResetPasswordModal } from './ResetPassword';
// // import { LoginAsModal } from './LoginAs';
// // import { UpdatePasswordModal } from './UpdatePassword';
// import { Feathers } from '../../../bootstrap/feathers/feathers';

// // import { ClearCartModal } from "./../../customers/Detail/ClearCartModal";

// const initialState = {
//   microsite: null,
//   isResetPasswordModalOpen: false,
//   isLoginAsModalOpen: false,
//   isUpdatePasswordModalOpen: false,
//   isClearCartModalOpen: false,
//   isSubmitting: {
//     clearCart: false
//   },
//   success: false,
//   errorMessage: '',
//   errorResetMessage: '',
//   errorClearCartMessage: '',
//   data: null
// };
// type State = typeof initialState;

// class Detail extends React.Component<DetailFeaturesProps, State, RouteComponentProps> {
//   state: State = {
//     microsite: null,
//     isResetPasswordModalOpen: false,
//     isLoginAsModalOpen: false,
//     isUpdatePasswordModalOpen: false,
//     isClearCartModalOpen: false,
//     isSubmitting: {
//       clearCart: false
//     },
//     success: false,
//     errorMessage: '',
//     errorResetMessage: '',
//     errorClearCartMessage: '',
//     data: null
//   };

//   async componentDidMount() {
//     this.loadMicrosite();

//     this.loadCustomer()
//   }

//   async loadCustomer() {
//     const id = this.props.match.params.id;

//     if (id) {
//       const customer = await Feathers.service('/microsites/customers').get(this.props.match.params.id, { query: {} })
//       this.setState({ ...this.state, data: customer })
//     }

//   }

//   async loadMicrosite() {
//     const microsite = await Feathers.service('/microsites').get(this.props.match.params.micrositeId);
//     this.setState({ ...this.state, microsite });
//   }

//   async handleLogInAs() {
//     await Feathers.service('/microsites/customers/login-as').create({

//     });

//   }
//   async handlePasswordReset() {
//     const { domain } = this.state.microsite;

//     // const ctx: any = {
//     //   tid: this.req.tenant.id,
//     //   userType: (this.req.tenant.microsite) ? UserType.MicrositeCustomer : UserType.Customer,
//     //   action: 'login-as',
//     //   customerId: this.req.session.customer.uid,
//     // };

//     // if (this.req.tenant.microsite) {
//     //   ctx.micrositeId = this.req.tenant.microsite;
//     //   ctx.micrositeCustomerId = this.req.session.micrositeCustomerId;
//     // }

//     // return feathers.service('/tokenizer/create').create(data);

//     const response = await Feathers.service('/microsite-user/reset-password').create({
//       microsite: this.props.match.params.micrositeId,
//       email: this.state.data.email,
//       url: `https://${domain}/reset-password-update`
//     });
//   }

//   handleResetPassword = async () => {
//     const { domain } = this.state.microsite;

//     try {
//       const res = await Feathers.service('/microsite-user/reset-password').create({
//         microsite: this.props.match.params.micrositeId,
//         email: this.state.data.email,
//         url: `https://${domain}/reset-password-update`
//       });

//       if (!res.result) {
//         this.setState({ ...this.state, errorResetMessage: res.message })
//       } else {
//         this.setState({ ...this.state, isResetPasswordModalOpen: false })
//       }
//     } catch (e) {
//       this.setState({ ...this.state, errorResetMessage: e.code && e.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again' })
//     }
//   }

//   handleUpdatePassword = async ({ password, confirmPassword }) => {
//     const { domain } = this.state.microsite;
//     const response = await Feathers.service('/microsite-user/password').create({
//       microsite: this.props.match.params.micrositeId,
//       email: this.state.data.email,
//       password, confirmPassword
//     });

//     this.setState({ ...this.state, isUpdatePasswordModalOpen: false });
//   }

//   handleSubmit = async (value: any) => {
//     this.setState({ ...this.state, errorMessage: '' })
//     const id = this.props.match.params.id

//     const isEdit = !!id;

//     const service = Feathers.service('/microsites/customers');

//     try {
//       const res: any = isEdit ? await service.patch(id, value, { query: {} }) :
//         await service.create(value, { query: {} });

//       if (res.result !== undefined && !res.result) {
//         this.setState({ ...this.state, errorMessage: res.message })
//       } else {
//         this.props.history.goBack()
//       }
//     } catch (e) {
//       this.setState({ ...this.state, errorMessage: e.code && e.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again' })
//     }
//   }

//   handleClearCartSubmit = async () => {
//     this.setState({
//       ...this.state,
//       isSubmitting: {
//         clearCart: true
//       },
//       errorClearCartMessage: ''
//     })

//     try {
//       const res: any = await Feathers.service("/cart/clear-active").create({
//         micrositeCustomer: this.props.match.params.id
//       });

//       if (res.result !== undefined && res.result === false) {
//         this.setState({ ...this.state, errorClearCartMessage: res.errorMessage || res.message })
//       } else {
//         this.setState({
//           ...this.state, isClearCartModalOpen: false, isSubmitting: {
//             clearCart: false
//           }
//         })
//       }

//     } catch (e) {

//       this.setState({
//         ...this.state,
//         isSubmitting: {
//           clearCart: false
//         },
//         errorClearCartMessage: e.code && e.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again'
//       })
//     }

//   }

//   render() {
//     const { match: { params: { micrositeId, id } } } = this.props;

//     const { data, microsite } = this.state;
//     if (!microsite) {
//       return <PreLoader />
//     }

//     const initialData = { ...data, microsite: micrositeId };
//     const pageTitle = data && data._id ? 'Edit' : 'New';
//     return (
//       <div>
//         <PageHeaderRow header={`Customer`} subheader='Microsite' subheaderId={this.state.microsite.humanId}>
//           {/* {data && data._id &&
//             <React.Fragment>
//               <Button content={'Clear Active Carts'} size={'mini'} onClick={() => this.setState({ ...this.state, isClearCartModalOpen: true, errorClearCartMessage: '' })} />
//               <Button content={'Reset Password'} size={'mini'} onClick={() => this.setState({ ...this.state, isResetPasswordModalOpen: true })} />
//               <Button content={'Update Password'} size={'mini'} onClick={() => this.setState({ ...this.state, isUpdatePasswordModalOpen: true })} />
//               <Button content={'Log In As'} size={'mini'} onClick={() => this.setState({ ...this.state, isLoginAsModalOpen: true })} />
//             </React.Fragment>
//           } */}
//           <Button content="Back" size={'mini'} onClick={() => this.props.history.goBack()} />
//         </PageHeaderRow>
//         {this.state.success && <Message success header="Email Sent!" content="A reset password email has been sent" onDismiss={() => this.setState({ ...this.state, success: false })} />}

//         <Segment>
//           <ContactForm
//             initialValues={initialData}
//             onSubmit={this.handleSubmit}
//             micrositeId={micrositeId}
//             errorMessage={this.state.errorMessage}
//             userID={id}
//           />
//         </Segment>

//         {/* {data && data._id && this.state.isResetPasswordModalOpen &&

//           <ResetPasswordModal
//             // microsite={this.state.microsite}
//             // customer={data}
//             isOpen={this.state.isResetPasswordModalOpen}
//             onClose={() => this.setState({ ...this.state, isResetPasswordModalOpen: false })}
//             onSubmit={this.handleResetPassword}
//             errorMessage={this.state.errorResetMessage}
//           />
//         } */}

//         {/* {data && data._id &&
//           <React.Fragment>
//             {this.state.isLoginAsModalOpen &&
//               <LoginAsModal
//                 microsite={microsite}
//                 micrositeCustomer={data}
//                 open={this.state.isLoginAsModalOpen}
//                 handleClose={() => this.setState({ ...this.state, isLoginAsModalOpen: false })}
//                 handleSubmit={() => {
//                   this.setState({ ...this.state, isLoginAsModalOpen: false })
//                 }}
//               />
//             }{
//               this.state.isUpdatePasswordModalOpen && <UpdatePasswordModal
//                 open={this.state.isUpdatePasswordModalOpen}
//                 onClose={() => this.setState({ ...this.state, isUpdatePasswordModalOpen: false })}
//                 onSubmit={this.handleUpdatePassword}
//               />
//             }

//             {this.state.isClearCartModalOpen && <ClearCartModal
//               open={this.state.isClearCartModalOpen}
//               onConfirm={this.handleClearCartSubmit}
//               errorMessage={this.state.errorClearCartMessage}
//               isSubmitting={this.state.isSubmitting.clearCart}
//               onClose={() => this.setState({ ...this.state, isClearCartModalOpen: false })}
//             />}

//           </React.Fragment>
//         } */}

//       </div>
//     );
//   }
// }

// export default Detail
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';

import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';

import feathers from '../../../bootstrap/feathers';

import type { initialValuesType } from './CustomerForm2';
import CustomerForm2 from './CustomerForm2';

const serviceName = '/microsites/customers';
const service = feathers.service(serviceName);
const commonParams = {
  query: {
    $populate: ['disabledProducts', 'orderApprovers'],
  },
};

export default function Detail(props: RouteComponentProps<{ id?: string; micrositeId?: string }>) {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);

  const isEdit = !!id;

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const handleSubmit = async (values: initialValuesType) => {
    const unset = {
      territory: values.territory ? undefined : 1,
      manager: values.manager ? undefined : 1,
    };

    const payload: any = {
      ...values,
      manager: values?.manager || undefined,
      territory: values?.territory || undefined,
      disabledProducts: values.disabledProducts?.map((p: any) => p?.value),
      orderApprovers: values.orderApprovalRequired
        ? values.orderApprovers?.map((p: any) => p?.value)
        : [],
    };

    if (Object.keys(unset).length) {
      payload.$unset = unset;
    }

    setIsSubmitting(true);
    try {
      const res: any = isEdit
        ? await service.patch(id, payload, { query: {} })
        : await service.create(payload, { query: {} });

      if (res.result !== undefined && !res.result) {
        setErrorMessage(res.message);
      } else {
        history.push(`/settings/microsites/${micrositeId}/customers`);
      }
    } catch (e) {
      const errMsg = e?.code < 500 ? e?.message : 'Something went wrong. Please try again later.';

      setErrorMessage(errMsg);
    }

    setIsSubmitting(false);
  };

  const initialData = {
    ...state.data,
    microsite: micrositeId,
    orderApprovers: state.data?.orderApprovers?.map((p: any) => ({
      value: p._id,
      label: `${p?.firstName ?? ''} ${p?.lastName ?? ''}`,
    })),
    disabledProducts:
      state.data?.disabledProducts?.map((p) => ({ label: p.key, value: p._id })) || [],
  };
  return (
    <>
      <PageHeaderRow header={`Customer`} subheader="Microsite">
        <Button
          bg="white"
          onClick={() => history.push(`/settings/microsites/${micrositeId}/customers`)}
        >
          Back
        </Button>
      </PageHeaderRow>
      <Box p={5} mt={3} bg="white">
        <CustomerForm2
          initialValues={initialData}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
        />
      </Box>
    </>
  );
}
