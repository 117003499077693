import * as React from 'react';
import {
  Button,
  Header,
  Checkbox,
  Form,
  Segment,
  Input,
  Image,
  Modal,
  Message,
  Dimmer,
  Divider,
  Loader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHeader,
} from 'semantic-ui-react';
import { ModelTypes } from '@inkcloud/icapi-types';
import { icapi, socket } from '../../bootstrap/feathers';

interface IBatchPrintModalProps {
  open: boolean;
  onClose: any;
  data: any[];
  mode: 'order' | 'order-item';
}

const BatchPrintModal: React.FunctionComponent<IBatchPrintModalProps> = (props) => {
  const { open, onClose, data, mode } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [resultUrl, setResultUrl] = React.useState<string>(null);
  const [id, setId] = React.useState<string>(null);
  const [numComplete, setNumComplete] = React.useState(0);
  const [selected, setSelected] = React.useState({});
  const [manualStatus, setManualStatus] = React.useState<string>(null);

  const handlePatch = (msg) => {
    if (msg._id === id) {
      setResultUrl(msg.resultUrl);
      setIsLoading(false);
      setManualStatus(null);
    }
  };

  const handleRendered = (msg) => {
    if (msg._id === id) {
      setNumComplete((n) => n + 1);
    }
  };

  const handleManualCheck = async () => {
    const printableCombined = await icapi.service('printable-combined').get(id);
    if (printableCombined.resultUrl) {
      setResultUrl(printableCombined.resultUrl);
      setIsLoading(false);
    } else {
      setManualStatus('The PDF is still being generated. Please check back in a few minutes.');
    }
  };

  React.useEffect(() => {
    setSelected(data.reduce((acc, cur) => ({ [cur._id]: true, ...acc }), {}));
  }, [data]);

  // Real-time: Subscribe to any changes and reload
  React.useEffect(() => {
    socket.service('printable-combined').on('patched', handlePatch);
    socket.service('printable-combined/pdfs').on('rendered', handleRendered);
    return () => {
      socket.service('printable-combined').removeListener('patched', handlePatch);
      socket.service('printable-combined/pdfs').removeListener('rendered', handleRendered);
    };
  }, [socket, id]);

  if (!data) {
    return null;
  }

  const handleSubmit = async () => {
    const payload: ModelTypes.PrintableCombined = {
      pdfs: Object.keys(selected)
        .filter((s) => selected[s])
        .map((s) => {
          const r = data.find((d) => d._id === s);
          return {
            service: 'printable/job-ticket',
            payload: {
              orderId: mode === 'order' ? r._id : r.order,
              orderItemId: mode === 'order-item' ? r._id : undefined,
            },
          };
        }),
    };
    setIsLoading(true);
    const printableCombined = await icapi.service('printable-combined').create(payload);
    setId(printableCombined._id);
  };

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Header>Batch Print</Modal.Header>
      <Modal.Content>
        {resultUrl && (
          <Message success>
            Batch Print PDF is ready.{' '}
            <a target="_blank" href={resultUrl}>
              Download PDF
            </a>
          </Message>
        )}
        {isLoading && (
          <>
            <label>
              Generating PDF... {numComplete} / {data.length} completed{' '}
            </label>

            <Message>
              If the process takes too long, you can click{' '}
              <Button onClick={handleManualCheck}>Check for Updates</Button> Manually refresh.
            </Message>
            {manualStatus && <Message>{manualStatus}</Message>}
          </>
        )}
        {!resultUrl && (
          <Segment loading={isLoading}>
            <Loader active={isLoading} content={'Generating PDF'} />
            Do you want to print job tickets for all items on this page?
            <br />
            <Segment style={{ maxHeight: 500, overflowY: 'scroll' }}>
              <Table size={'small'}>
                <TableHeader>
                  <TableRow>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Order #</Table.HeaderCell>
                    <Table.HeaderCell>Customer Ref</Table.HeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data.map((r) => (
                    <TableRow key={r._id}>
                      <TableCell>
                        <input
                          type="checkbox"
                          checked={selected[r._id]}
                          onClick={() => setSelected({ ...selected, [r._id]: !selected[r._id] })}
                        />
                      </TableCell>
                      <TableCell>{r.humanId}</TableCell>
                      <TableCell>{r.customerJobName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Segment>
            <Form>
              <Button primary type="submit" onClick={handleSubmit}>
                Print
              </Button>
            </Form>
          </Segment>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default BatchPrintModal;
